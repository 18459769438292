import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import {
  Nudge,
  Container,
  Section,
  Flex,
  Text,
  SuperHeading,
  Box,
} from "../components/ui"
import * as styles from "../components/about-hero.css"

export default function Blogs() {
  return (
    <Layout>
      <Container
        style={{
          display: "flex",
          height: "80vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <Box center>
          <SuperHeading className={styles.aboutHeroHeader}>
            Blogs Coming Soon
          </SuperHeading>
          <Text className={styles.aboutHeroText}>
            We will detail our journey
          </Text>
        </Box>
      </Container>
    </Layout>
  )
}
